<template>
	<section class="portfolio-details" :key="id">
		<div class="max-width-1000">
			<el-breadcrumb separator-class="el-icon-arrow-right">
				<el-breadcrumb-item to="/portfolio">系列作品</el-breadcrumb-item>
				<el-breadcrumb-item>{{_portfolio.title}}</el-breadcrumb-item>
			</el-breadcrumb>
			<br>
			<h1>{{_portfolio.title}}</h1>
			<h2>{{_portfolio.sub_title}}</h2>
			<hr>
			<template v-if="$root.rwd < 3 && _portfolio.banners">
				<br>
				<carousel :options="{ interval:5000, duration:1000, loop:true }" control indicator>
					<template v-for="({url}, i) in _portfolio.banners">
						<img :src="url" width="100%" :key="i">
					</template>
				</carousel>
			</template>
			<template v-else-if="_portfolio.banners_mobile">
				<br>
				<carousel :options="{ interval:5000, duration:1000, loop:true }" control indicator>
					<template v-for="({url}, i) in _portfolio.banners_mobile">
						<img :src="url" width="100%" :key="i">
					</template>
				</carousel>
			</template>
			<template v-if="youtubeId">
				<br>
				<div class="youtube">
					<iframe :src="`https://www.youtube.com/embed/${youtubeId}`" frameborder="0" allowfullscreen />
				</div>
			</template>
			<br>
			<div v-html="_portfolio.details" />
			<br>
			<br>
		</div>
	</section>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import querystring from "querystring";
export default {
	components: {
		Carousel: () => import("@/components/carousel"),
		Thumb: () => import("@/components/thumb"),
	},
	props: ["id"],
	computed: {
		...mapState("portfolio", ["_portfolio"]),
		youtubeId() {
			const { youtube } = this._portfolio;
			return youtube && querystring.parse(new URL(youtube).searchParams.toString())["v"] || ""
		}
	},
	watch: {
		id(val) {
			this.init();
		}
	},
	methods: {
		...mapActions("portfolio", ["_getData"]),
		async init() {
			await this._getData(this.id);
		}
	},
	created() {
		this.init();
	}
}
</script>

<style lang="scss" scoped>
.portfolio-details {
	padding: 50px 5%;
	position: relative;
	background-size: 100%;
	background-repeat: no-repeat;
	background-image: url("../../../assets/background/brush2.png"),
		url("../../../assets/background/dot1.png"),
		url("../../../assets/background/dot2.png");
	background-position: center 70%, center 80%, center 80%;
}
</style>